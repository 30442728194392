import React from "react";
import gerb from "../../../assets/images/gerb.png";
import karta from "../../../assets/images/kart.png";
import ScrollToTop from "./scrollToTop/ScrollToTop";
import { FiYoutube } from "react-icons/fi";
// import { FiInstagram } from "react-icons/fi";
import { FaTelegramPlane } from "react-icons/fa";
import { BsFacebook } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="lg:h-[400px] md:h-[400px] bg-[#040D33] text-white">
      <div className="max-w-[1280px] mx-auto lg:flex md:flex justify-between pt-4 lg:px-0 md:px-3 px-4">
        <div className="lg:w-[650px] md:w-[600px] flex flex-col gap-4 lg:text-[17px] md:text-[16px]">
          <div className="flex gap-2 items-center pt-[6px]">
            <img src={gerb} alt="gerb" width="50px" height="50px" />
            <h2 className="text-xl font-semibold text-white">
              {t("Ohangaron shahar hokimligi")}
            </h2>
          </div>
          <p className="lg:w-[600px]">
            {t(
              "Sayt Toshkent viloyati hokimligi huzuridagi, Axborot kommunikatsiya texnologiyalarini rivojlantirish markazi mutaxassislari tomonidan yaratilgan © 2023"
            )}
          </p>
          <p className="lg:w-[550px]">
            {t(
              "Ushbu sayt materiallaridan foydalanganda www.ohangaron-sh.uz saytiga havola qilish majburiydir"
            )}
          </p>
          <a className="hover:text-gray-400 duration-300" href="/saytxaritasi">
            {t("Sayt xaritasi")}
          </a>
          <div className="flex gap-6 mt-8">
            {/* <a
              className="hover:text-[#963f9d] duration-300"
              href="https://www.instagram.com/axborot_xizmati_77?igshid=yrbeg6ojuocd"
              target="_blank"
              rel="noreferrer"
            >
              <FiInstagram size={26} />
            </a> */}
            <a
              className="hover:text-[#CD201F] duration-300"
              href="https://youtube.com/@ohangaronshaharhokimligi?si=BC3nBaXfsvy1Iyoo"
              target="_blank"
              rel="noreferrer"
            >
              <FiYoutube size={29} />
            </a>
            <a
              className="hover:text-[#0088cc] duration-300"
              href="https://t.me/ohangaronshahar"
              target="_blank"
              rel="noreferrer"
            >
              <FaTelegramPlane size={28} />
            </a>
            <a
              className="hover:text-[#3b5998] duration-300"
              href="https://www.facebook.com/people/Ohangaron-Shahar-Hokimligi/pfbid0sh61pSMfeb58d4PzcD6kikb7Yef1uJrNef9eWbPpckhsr84yHP3tJKNWdg21rz4Pl/"
              target="_blank"
              rel="noreferrer"
            >
              <BsFacebook size={26} />
            </a>
          </div>
        </div>
        <div className="flex flex-col gap-2 mt-[10px]">
          <p>{t("Bizning manzil:")}</p>
          <p>{t("Ohangaron shahar Y.Oxunboboev ko'chasi, 1-uy.")}</p>
          <a
            href="https://www.google.com/maps/place/%D0%9E%D1%85%D0%B0%D0%BD%D0%B3%D0%B0%D1%80%D0%BE%D0%BD+%D1%88%D0%B0%D1%85%D0%B0%D1%80+%D1%85%D0%BE%D0%BA%D0%B8%D0%BC%D0%B8%D1%8F%D1%82%D0%B8/@40.9068559,69.6410186,17z/data=!4m14!1m7!3m6!1s0x38ae330be53e2413:0x6d60f669e99cc0ae!2z0J7RhdCw0L3Qs9Cw0YDQvtC9INGI0LDRhdCw0YAg0YXQvtC60LjQvNC40Y_RgtC4!8m2!3d40.9068519!4d69.6435935!16s%2Fg%2F11fm6qy80c!3m5!1s0x38ae330be53e2413:0x6d60f669e99cc0ae!8m2!3d40.9068519!4d69.6435935!16s%2Fg%2F11fm6qy80c?entry=ttu"
            target="_blank"
            rel="noreferrer"
            className="overflow-hidden bg-no-repeat bg-cover"
          >
            <img
              src={karta}
              alt="harita"
              a
              width="400px"
              height="400px"
              className="hover:scale-125 object-cover transition duration-700 ease-in-out"
            />
          </a>
          <p>
            {t("Tel: +998 70 645 10 09")} <br />
          </p>
          <p>{t("Murojaat qilish vaqti: 9:00 / 18:00")}</p>
        </div>
      </div>

      <div className="lg:block md:block hidden">
        <ScrollToTop />
      </div>
    </div>
  );
};

export default Footer;
