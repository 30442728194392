import { useTranslation } from "react-i18next";
import ohang from "../../assets/images/ohang.jpg";

const RegionInfo = () => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-center items-center px-[100px] gap-8 h-[330px]">
      <div className="w-[600px]">
        <img src={ohang} alt="" />
      </div>
      <div className="w-[865px] h-[170px]">
        <h4 className="font-[700] mb-[10px] mt-[-50px] text-[36px] text-#121221">
          {t("Ohangaron shahar haqida")}
        </h4>
        <p>
          <strong className="text-[20px]">Ohangaron </strong> -
          Oʻzbekiston uchun katta sanoat ahamiyatiga ega. Toshkent-Angren temir
          yoʻl yoʻnalishida joylashgan. Shaharda yirik korxonalar sement
          (1966-yil ochilgan), shifer zavodlari, temir-beton buyumlar zavodi,
          „Santexlit“ zavodi (1996-yilda yopilgan), asbest-sement va issiqlik
          izolyatsion mahsulotlar, qurilish materiallari va plastmassa buyumlari
          zavodlari, gʻisht ishlab chiqarish, rezina buyumlar, paxta iplari va
          kalava ishlab chiqarish kabi korxona va zavodlar bor Shuningdek,
          yashash va obodonlashtirish infratuzilmasi — uylar, bolalar
          bogʻchalari, maktablar, bank muassasalari va aviakassalar mavjud.
        </p>
      </div>
    </div>
  );
};

export default RegionInfo;
