import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "@mantine/form";
import { useSendMsgMutation } from "../../../hooks/mutation/useSendMsgMutation";
import { meeting } from "./data.js";
import { toast } from "react-hot-toast";
import { useGetHokimDetail } from "../../../hooks/query";
import { useTranslation } from "react-i18next";

const SendMsgRahbariyat = () => {
  const { t } = useTranslation();
  const useSendMsgMutations = useSendMsgMutation();
  const { rhid } = useParams();
  const useGetHokimDetailInfo = useGetHokimDetail({
    id: rhid,
  });
  const [rhemail, setRhemail] = useState("");
  const form = useForm({});

  const handleSendMsg = (e) => {
    const formData = new FormData();
    formData.append("f_name", e.f_name);
    formData.append("email", e.email);
    formData.append("number", e.number);
    formData.append("capital", e.capital);
    formData.append("birth_day", e.birth_day);
    formData.append("capital", e.capital);
    formData.append("appeal", e.appeal);
    formData.append("to_email", rhemail);
    formData.append("checkbox", e.checkbox);
    const createCandidates = useSendMsgMutations.mutateAsync(formData);

    createCandidates
      .then((res) => {
        toast.success("Malumot jo'natildi", {
          duration: 2200,
        });
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  useEffect(() => {
    setRhemail(useGetHokimDetailInfo.data?.email);
    window.scrollTo(0, 0);
  }, [useGetHokimDetailInfo.data?.email]);
  console.log(useGetHokimDetailInfo);

  return (
    <div className="lg:mx-0 md:mx-[100px] mx-[40px]">
      <h3 className="text-center lg:text-2xl md:text-xl text-[14px] font-semibold">
        {t("Ohangaron shahar hokimiga murojaat yuborish")}
      </h3>
      <div>
        <form onSubmit={form.onSubmit(handleSendMsg)}>
          <div>
            <article>
              <label htmlFor="Ismi">{t("F.I.O.")}</label>
              <input
                className="w-full py-2 rounded  appearance-none block bg-gray-200 text-gray-700 border border-gray-200 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="Ismi"
                type="text"
                placeholder={t("F.I.O.")}
                {...form.getInputProps("f_name")}
              />
            </article>
            <article>
              <label htmlFor="Elektron manzil">{t("Elektron pochta")}</label>
              <input
                className="py-2 w-full rounded  appearance-none block bg-gray-200 text-gray-700 border border-gray-200 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="Elektron manzil"
                {...form.getInputProps("email")}
                type="text"
                placeholder="Elektron pochta"
              />
            </article>
            <article>
              <label htmlFor="Shaxar yoki Tuman">
                {t("Shaxar yoki Tuman")}
              </label>
              <input
                className="py-2 rounded w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="Shaxar yoki Tuman"
                {...form.getInputProps("capital")}
                type="text"
                placeholder={t("Shaxar yoki Tuman")}
              />
            </article>
            <article>
              <label htmlFor="Telafon raqam">{t("Telafon raqam")}</label>
              <input
                className="py-2 rounded w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id={t("Telafon raqam")}
                type="number"
                placeholder="+998 "
                {...form.getInputProps("number")}
              />
            </article>
            <article>
              <label htmlFor="Tug'ulgan sana">{t("Tug'ilgan sana")}</label>
              <input
                className="py-2 rounded w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="Tug'ulgan sana"
                type="date"
                placeholder={t("Tug'ilgan sana")}
                {...form.getInputProps("birth_day")}
              />
            </article>
            <article>
              <label>{t("Uchrashuv belgilash")}</label>
              <select
                className="py-2 rounded w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                {...form.getInputProps("meet")}
              >
                {meeting.map((item, index) => (
                  <option
                    {...form.getInputProps("meet")}
                    value={item.title}
                    key={index}
                  >
                    {item.title}
                  </option>
                ))}
                <option selected disabled>
                  {t("Uchrashuv belgilash")}
                </option>
              </select>
            </article>
            <input
              className="hidden"
              type="text"
              placeholder={t("Email")}
              value={rhemail}
            />

            <article>
              <label htmlFor=" Murojatnoma">{t("Murojatnoma mazmuni")}</label>
              <textarea
                className="mb-10 w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="Murojatnoma"
                placeholder={t("Murojaat matnini shu yerga kiriting...")}
                cols="50"
                rows="5"
                maxi
                {...form.getInputProps("appeal")}
              />
            </article>
            <div className="flex mb-[100px] items-center justify-between">
              <div className="text-black flex items-center w-[35vw]">
                {t("Barcha shartlar bilan tanishib chiqdim")}
                <input
                  className="flex items-center ml-4 text-2xl"
                  type="checkbox"
                  {...form.getInputProps("checkbox")}
                />
              </div>

              <div>
                <button
                  className="bg-[#22577a] hover:bg-[#38a3a5] duration-300 text-white px-6 py-2 mt-4 rounded"
                  type="submit"
                >
                  {t("Yuborish")}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SendMsgRahbariyat;
